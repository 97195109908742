import "./media-topper.scss";

import cn from "clsx";

import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { EditAttributes } from "design-system/types/types";
import { shouldDecreaseTextSize } from "design-system/utils/text";
import { SimpleTheme } from "design-system/utils/theme";
import { ReactNode } from "react";

export interface MediaTopperProps {
  title?: string;
  isSeamless?: boolean;
  assets: Array<MediaAssetProps>;
  subtitle?: ReactNode;
  cta?: CtaLinkProps;
  theme?: SimpleTheme;
  hiddenTitle?: string;
  editAttributes?: {
    title?: EditAttributes;
    subtitle?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - A [landing page](/story/example-pages-landing-pages-card-topper--story-alt)
 *
 * - **`id: CT-001-002-00`**
 * - **`data-region: ct__page__media`**
 */
export function MediaTopper({
  title,
  hiddenTitle,
  assets,
  subtitle,
  cta,
  isSeamless = false,
  theme = "light",
  editAttributes,
}: MediaTopperProps) {
  if (!title) return null;

  const topperClass = cn(
    "hbs-media-topper hbs-topper",
    assets.length === 3 && "hbs-media-topper--3",
    isSeamless || "hbs-media-topper--w-background",
    !subtitle && "hbs-media-topper--no-subtitle",
  );

  const titleClass = cn(
    "hbs-media-topper__title",
    shouldDecreaseTextSize(title, 20, 18) && "hbs-media-topper__title--long",
  );

  const showTopperDek = subtitle || cta;

  const HeadingLevel = hiddenTitle ? "div" : "h1";

  return (
    <div
      className="hbs-media-topper-wrapper hbs-topper-wrapper"
      data-theme={theme}
      data-region="ct__page__media"
    >
      <div className={topperClass}>
        <div className={titleClass}>
          {hiddenTitle && (
            <h1 className="hbs-global-visually-hidden">{hiddenTitle}</h1>
          )}
          {title && (
            <HeadingLevel {...editAttributes?.title}>{title}</HeadingLevel>
          )}
        </div>

        {showTopperDek && (
          <div className="hbs-media-topper__child">
            {subtitle && (
              <div
                {...editAttributes?.subtitle}
                className="hbs-media-topper__subtitle"
              >
                {subtitle}
              </div>
            )}

            {cta && (
              <div className="hbs-media-topper__cta">
                <CtaLink {...cta} isSmall />
              </div>
            )}
          </div>
        )}

        <div className="hbs-media-topper__media">
          {assets.map((asset, i) => {
            if (asset?.image) {
              asset.image.loading = "eager";
              asset.image.aspectRatio =
                assets.length === 1
                  ? "16/9"
                  : assets.length === 3 && i !== 1
                    ? "3/4"
                    : "3/2";
            }

            return (
              <div className="hbs-media-topper__media-asset" key={i}>
                <MediaAsset {...asset} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
